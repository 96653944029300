<script>
export default {
  name: 'report-layout',
  props: {
    financing: Object,
    settings: Object
  }
};
</script>

<template>
  <div
    style="
      background-color: #fff;
      padding: 8px 24px 24px 24px;
      font-family: serif;
    "
  >
    <div class="texts">
      <h1
        style="
          color: #000;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
        "
      >
        Anexo 27-I
      </h1>
      <p
        style="
          color: #000;
          text-transform: uppercase;
          text-align: center;
          font-weight: 500;
        "
      >
        Informações sobre o início de oferta pública de valores mobiliárias
        emitidos por sociedade empresária de pequeno porte realizada com
        dispensa de registro na comissão de valores mobiliários
      </p>
    </div>
    <div
      class="item"
      style="margin-top: 34px; border: 1px solid #000; padding: 4px 8px"
    >
      <span style="color: #000; text-transform: uppercase; font-size: 16px"
        >Sociedade empresária de pequeno porte</span
      >
      <br />
      <span style="color: #000; font-size: 16px">Nome: {{ ( financing && financing.dados_complementares.empresa ) ? financing.dados_complementares.empresa.nome : '' }}</span><br />
      <span style="color: #000; font-size: 16px">CNPJ: {{ ( financing && financing.dados_complementares.empresa ) ? financing.dados_complementares.empresa.documento.numero : '' }}</span><br />
      <span style="color: #000; font-size: 16px">Tipo societário: {{ ( financing && financing.fields && financing.fields['Tipo Societário'] ) ? financing.fields['Tipo Societário'].value : '' }}</span><br />
      <span style="color: #000; font-size: 16px">Endereço da sede: {{ ( financing && financing.dados_complementares.empresa ) ? financing.dados_complementares.empresa.endereco.rua : '' }} {{ ( financing && financing.dados_complementares.empresa ) ? financing.dados_complementares.empresa.endereco.numero : '' }}, {{ ( financing && financing.dados_complementares.empresa ) ? financing.dados_complementares.empresa.endereco.cidade : '' }}/{{ ( financing && financing.dados_complementares.empresa ) ? financing.dados_complementares.empresa.endereco.estado : '' }}</span><br />
      <span style="color: #000; font-size: 16px"
        >Nome do representante legal: {{ ( financing && financing.dados_complementares.empresa ) ? financing.dados_complementares.empresa.dados_complementares.responsavel.nome : '' }}</span
      ><br />
      <span style="color: #000; font-size: 16px">Telefone para contato: {{ ( financing && financing.dados_complementares.empresa ) ? financing.dados_complementares.empresa.dados_complementares.responsavel.telefone.telefone : '' }}</span
      ><br />
      <span style="color: #000; font-size: 16px">E-mail: {{ ( financing && financing.dados_complementares.empresa ) ? financing.dados_complementares.empresa.dados_complementares.responsavel.email.email : '' }}</span><br />
      <span style="color: #000; font-size: 16px"
        >Página da sociedade na rede mundial de computadores (se houver): {{ ( financing && financing.fields && financing.fields['Site'] ) ? financing.fields['Site'].value : '' }}</span
      ><br />
      <span style="color: #000; font-size: 16px"
        >Receita bruta no último exercício social: {{ ( financing && financing.fields && financing.fields['Receita Bruta Último Exercício'] ) ? financing.fields['Receita Bruta Último Exercício'].value : 0 | currency }}</span
      ><br />
    </div>
    <div
      class="item"
      style="border-top: none; border: 1px solid #000; padding: 4px 8px"
    >
      <span style="color: #000; text-transform: uppercase; font-size: 16px"
        >Dados da oferta</span
      >
      <br />
      <span style="color: #000; font-size: 16px"
        >Quantidade de valores mobiliários objeto da oferta: {{ ( financing && financing.fields && financing.fields['Quantidade Inicial de Ações'] ) ? financing.fields['Quantidade Inicial de Ações'].value : 0 }}</span
      ><br />
      <span style="color: #000; font-size: 16px"
        >Descrição do valor mobiliário ofertado:</span
      ><br />
      <span style="color: #000; font-size: 16px">Espécie:</span><br />
      <span style="color: #000; font-size: 16px">Classe:</span><br />
      <span style="color: #000; font-size: 16px">Preço unitário: {{ ( financing && financing.fields && financing.fields['Valor da Ação'] ) ? financing.fields['Valor da Ação'].value : 0 | currency }}</span><br />
      <span style="color: #000; font-size: 16px"
        >Valor alvo máximo da oferta: {{ ( financing && financing.totais ) ? financing.totais.valor_meta_formatado : '' }}</span
      ><br />
      <span style="color: #000; font-size: 16px">Data de início da oferta: {{ ( financing && financing.dates ) ? financing.dates.started_at : '' | moment('LLL') }}</span
      ><br />
      <span style="color: #000; font-size: 16px"
        >Identificação da Plataforma Eletrônica de Investimento
        Participativo: {{ settings.platformIdentifier }}</span
      ><br />
      <span style="color: #000; font-size: 16px"
        >Página da plataforma na rede mundial de computadores: {{ settings.site }}</span
      ><br />
    </div>
  </div>
</template>
